import styled from "styled-components";
import {ReactComponent as ChevronLeft} from '../../assets/icons/chevron-left.svg';
import {useFormContext} from '../../contexts/FormContext';
import {Button} from '../Button/Button';
import {Statuses} from '../../constants/enums';
import {useOutletContext} from "react-router-dom";
import {totalAmount} from '../FormFields/OrderSummary';

const StyledFormNavigation = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 50px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-up);
    padding: 20px 40px;
    border-radius: 0 0 12px 12px;

    @media screen and (max-width: 768px) {
        gap: 4px;
        position: fixed;
        z-index: 99;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 0;
        padding: 20px 16px;
    }
`;

const StyledChevronLeft = styled(ChevronLeft)`
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    @media screen and (max-width: 475px) {
        width: 20px;
        height: 20px;
    }
`;

const PreviousButton = styled(Button)`
    flex-shrink: 0;
    
    @media screen and (max-width: 475px) {
        font-size: 1.6rem;
        padding-left: 0;
        padding-right: 12px;
    }
`;

const SubmitButton = styled(Button)`
    flex-basis: 200px;
`;

const FormNavigation = ({handlePreviousStep, maxSteps, status}) => {
    const {stepIndex} = useFormContext();
    const {data} = useOutletContext();
    const loading = status === Statuses.LOADING || status === Statuses.SUBMITTING;
    const hasOrderSummary = data?.steps?.some(step =>
        step?.questions?.some(question => question?.type === 'orderSummary')
    );
    const lastButtonText = (hasOrderSummary && totalAmount > 0) ? "Start payment" : "Finish form";

    return (
        <StyledFormNavigation>
            {stepIndex > 0 && (
                <PreviousButton variant="secondary" onClick={handlePreviousStep} disabled={loading}><StyledChevronLeft /> Previous step</PreviousButton>
            )}

            <SubmitButton form="form-route" type="submit" disabled={loading} loading={loading}>{stepIndex + 1 >= maxSteps ? lastButtonText : "Next step"}</SubmitButton>
        </StyledFormNavigation>
    );
}

export default FormNavigation;