import styled from "styled-components";
import {Text} from "../Text/Text";
import {ReactComponent as Success} from '../../assets/icons/success.svg';

const StyledStep = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
`;

const StepNumber = styled(Text).attrs({
    $weight: "bold",
    $noMargin: true,
})`
    display: ${({$status}) => $status === "complete" ? "none" : "flex"};
    position: relative;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; // todo
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    border: 2px solid ${({$status}) => $status === "active" ? "var(--color-progress-step-active)" : "var(--color-progress-step-text)"};
    color: ${({$status}) => $status === "active" ? "var(--color-white)" : "var(--color-progress-step-text)"};
    background: ${({$status}) => $status === "active" ? "var(--color-progress-step-active)" : "var(--color-white)"};
`;

const StepText = styled(Text).attrs({
    $weight: "bold",
    $noMargin: true,
})`
    position: absolute;
    top: 110%;
    letter-spacing: -1px;
    line-height: 18.75px;
    white-space: nowrap;
    text-align: center;
    color: ${({$status}) => $status === "active"  || $status === "complete" ? "var(--color-progress-step-active)" : "var(--color-progress-step-text)"};

    @media screen and (max-width: 768px) {
        word-wrap: normal;
        white-space: pre-wrap;
    }
`;

const StepDivider = styled.span`
    display: ${({$isFinalStep}) => $isFinalStep ? 'none' : 'flex'};
    flex-grow: 1;
    border: ${({$status}) => $status === "complete" ? "1px solid var(--color-progress-step-active)" : "1px solid var(--color-progress-step-text)"};
    opacity: .4;
    max-width: 160px;
`;

const StyledComplete = styled(Success)`
    display: ${({$status}) => $status === "complete" ? "flex" : "none"};
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 50%;
    border: 2px solid var(--color-progress-step-active);
    background: var(--color-progress-step-active);
`;

export const Step = ({ number, title, status = "inactive", isFinalStep = false}) => {
    return (
        <>
            <StyledStep>
                <StepNumber $status={status}>{number}</StepNumber>
                <StyledComplete $status={status} />
                <StepText $status={status}>{title}</StepText>
            </StyledStep>
            <StepDivider $isFinalStep={isFinalStep} $status={status}/>
        </>
    );
}