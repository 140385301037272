import qs from 'qs';

export async function getData(endpoint, options = {}) {
    const {
        headers = {},
        queryParams = {},
        handleErrorLocally = false
    } = options;

    const queryString = qs.stringify(queryParams, { indices: false });

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/introweken/${endpoint}${queryString ? `?${queryString}` : ''}`;

    const res = await fetch(url, {
        headers: await prepareHeaders(headers),
    });

    return await handleResponse(res, handleErrorLocally)
}

export async function postData(endpoint, data, options = {}) {
    const {
        headers = {},
        queryParams = {},
        handleErrorLocally = false
    } = options;

    const queryString = qs.stringify(queryParams, { indices: false });

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/introweken/${endpoint}${queryString ? `?${queryString}` : ''}`;

    const res = await fetch(url, {
        method: 'POST',
        headers: await prepareHeaders(headers, (data instanceof FormData) ? null : 'application/json'),
        body: data instanceof FormData ? data : JSON.stringify(data),
    });

    return await handleResponse(res, handleErrorLocally)
}

const prepareHeaders = async (headers, contentType = 'application/json') => {
    return {
        ...headers,
        ...(contentType && {'Content-Type': contentType}),
        'Accept': 'application/json'
    };
}

const handleResponse = async (response, handleErrorLocally) => {
    if (!response.ok && !handleErrorLocally) {
        switch (response.status) {
            case 400:
                throw new Response("Bad Request", { status: 400});
            case 401:
                throw new Response("Unauthenticated", { status: 401});
            case 402:
                throw new Response("Payment Required", { status: 402});
            case 404:
                throw new Response("Not Found", { status: 404});
            case 409:
                throw new Response("Conflict error", { status: 409});
            default:
                throw new Response("Something went wrong", { status: 500});
        }
    }

    try {
        return await response.json();
    } catch {
        // Catch empty 200 response
        return "";
    }
}


