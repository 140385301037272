import React from 'react';
import ReactDOM from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import Root, {RootFallback} from "./routes/Root/Root";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {FormLayout, formLayoutLoader} from "./layouts/FormLayout";
import ErrorBoundary from "./routes/Error/ErrorBoundary";
import FormRoute, {formRouteAction} from "./routes/FormRoute/FormRoute";
import NoMatch from "./routes/Error/NoMatch";
import FormProvider from "./contexts/FormContext";
import FormResult, {formResultLoader} from "./routes/FormResult/FormResult";
import FormSuccess from "./routes/FormSuccess/FormSucess";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <RootFallback />,
        children: [
            {
                loader: formLayoutLoader,
                element: <FormLayout />,
                errorElement : <ErrorBoundary />,
                children: [
                    {
                        path: ":formTarget?/step?/:stepNumber?",
                        action: formRouteAction,
                        element: <FormRoute />,
                        errorElement: <ErrorBoundary />,
                    },
                    {
                        path: ":formTarget/success",
                        element: <FormSuccess />,
                        errorElement: <ErrorBoundary />,
                    },
                    {
                        loader: formResultLoader,
                        path: "form/:formTarget/paymentresult",
                        element: <FormResult />,
                        errorElement: <ErrorBoundary />,
                    }
                ],
            },
            {
                path: "*",
                element: <NoMatch />,
            },
        ],
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        {/*<React.StrictMode>*/}
            <HelmetProvider>
                <FormProvider>
                    <RouterProvider router={router} />
                </FormProvider>
            </HelmetProvider>
        {/*</React.StrictMode>*/}
    </>
);