import styled from 'styled-components';
import {isRouteErrorResponse, Link, useRouteError} from 'react-router-dom';
import {Heading2} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import FormFooter from "../../components/Form/FormFooter";
import {Container} from "../../components/Utils/Utils";
import {ReactComponent as Cross} from '../../assets/icons/cross.svg';
import {ReactComponent as Success} from '../../assets/icons/success.svg';
import {ReactComponent as Exclamation} from '../../assets/icons/exclamation.svg';
import {Button} from "../../components/Button/Button";

const StyledErrorBoundary = styled.div`
    padding: 40px 0;
    margin: auto 0;
    
    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const StyledContainer = styled(Container)`
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const Inner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    padding: 52px 40px;
    
    @media screen and (max-width: 768px) {
        padding: 40px 16px;
    }
`;

const Icon = styled.div`
    padding: 12px;
    border-radius: 50%;
    background-color: ${({$status}) => $status === "action" ? "var(--color-status-action)" : $status === "success" ? "var(--color-status-success)" : "var(--color-status-failed)"};
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
`;

const StyledCross = styled(Cross)`
    width: 56px;
    height: 56px;
`;

const StyledSuccess = styled(Success)`
    width: 56px;
    height: 56px;
`;

const StyledExclamation = styled(Exclamation)`
    width: 56px;
    height: 56px;
`;

const PaymentButton = styled(Button)`
    margin-bottom: 10px;
`;

const ErrorBoundary = () => {
    const error = useRouteError();
    const email = "info@owl.leidenuniv.nl";

    const startPayment = () => {
        // TODO: Get payment url and go to that page
    }

    let title = "Something went wrong";
    let icon = <StyledCross />;
    let text = "Please try again later...";
    let button;
    let status = "failure";
    if (isRouteErrorResponse(error)) {
        switch (error.status) {
            case 400:
                title = "Bad Request";
                break;
            case 401:
                title = "This link is invalid"
                break;
            case 402:
                title = "Payment required";
                icon = <StyledExclamation />;
                text = "You need to finish your payment for your registration to be completed";
                button = <PaymentButton onClick={startPayment}>Start payment</PaymentButton>
                status = "action";
                break;
            case 404:
                title = "Not found";
                break;
            case 409:
                title = "Already registered";
                icon = <StyledSuccess />;
                text = "You already completed your registration!"
                status = "success"
                break;
            default:
                title = "An unexpected error occured";
                text = "Please try again later...";
                break;
        }
    }

    return (
        <StyledErrorBoundary>
            <StyledContainer>
                <Inner>
                    <Icon $status={status}>
                        {icon}
                    </Icon>
                    <Heading2 $align="center">{title}</Heading2>
                    <Text $align="center">{text}</Text>
                    {button}
                    <Text $align="center">For questions, please contact <Link to={"mailto: " + email}>{email}</Link></Text>
                </Inner>
            </StyledContainer>

            <Container>
                <FormFooter />
            </Container>
        </StyledErrorBoundary>
    );
}

export default ErrorBoundary;