import styled from "styled-components";

const StyledFormFooter = styled.footer`
    display: flex;
    padding: 30px 0 50px 0;
    gap: 40px;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        row-gap: 20px;
        padding: 20px 16px 105px 16px;
    }
`;

const ReferenceLink = styled.a.attrs({
    target: "_blank",
    rel: "noreferrer"
})`
    font-size: var(--fs-footer);
    color: var(--color-footer-text);
    
    @media screen and (max-width: 768px) {
        margin: 0 auto;
        text-align: center;
    }
`;

const Copyright = styled.div`
    font-size: var(--fs-footer);
    color: var(--color-footer-text);
    margin-left: auto;

    @media screen and (max-width: 768px) {
        margin: 0 auto;
        text-align: center;
    }
`;

export const FormFooter = () => {
    return (
        <StyledFormFooter>
            <ReferenceLink href="https://www.google.com">Winter OWL Website</ReferenceLink>
            <ReferenceLink href="https://www.google.com">Terms of Privacy</ReferenceLink>
            <Copyright>&copy; Winter OWL {new Date().getFullYear()}</Copyright>
        </StyledFormFooter>
    );
}

export default FormFooter;