import {Step} from "./Step";
import styled from "styled-components";
import {useLoaderData, useMatch} from "react-router-dom";
import {useFormContext} from '../../contexts/FormContext';

const StyledStepsContainer = styled.div`
    padding: 40px 40px 0 40px;

    @media screen and (max-width: 768px) {
        padding: 30px 35px 30px 35px;
    }
`;

const Inner = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0;
`;

export const StepsContainer = () => {
    const data = useLoaderData();
    const {stepIndex} = useFormContext();
    const matchFormSuccess = useMatch("/:formTarget/success")
    const matchFormResult = useMatch("/form/:formTarget/paymentresult")

    if (Boolean(matchFormSuccess) || Boolean(matchFormResult) || !(data?.steps?.length > 1)) return null;

    const getStatus = (step) => {
        if (stepIndex === data?.steps?.indexOf(step)) {
            return "active"
        } else if (data?.steps?.indexOf(step) < stepIndex) {
            return "complete"
        }
    }

    return (
        <StyledStepsContainer>
            <Inner>
                {data?.steps?.map(step => (
                    <Step
                        key={step.id}
                        number={data?.steps?.indexOf(step) + 1}
                        title={step.title}
                        status={getStatus(step)}
                        isFinalStep={data?.steps?.indexOf(step) + 1 === data?.steps?.length}>
                    </Step>
                ))}
            </Inner>
        </StyledStepsContainer>
    );
}